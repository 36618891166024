import React from "react"
import { graphql } from "gatsby"
import Picture from "gatsby-image"
import Layout from "../../components/layout"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const ParkingAdvicePage = ({ data }) => {
  return (
    <Layout>
      <h1>Parking Areas</h1>
      <div style={{ float: `left`, maxWidth: `800px` }}>
        <h2>Where can I park?</h2>
        <hr />
        <p
          style={{
            maxWidth: `800px`,
            display: `block`,
            marginLeft: `auto`,
            marginRight: `auto`,
          }}
        >
          <Picture
            className="parking-areas.jpg"
            fluid={data.file.childImageSharp.fluid}
            alt="parking-areas.jpg"
          />
        </p>
        <p>
        © Crown copyright and database rights 2021 Ordnance Survey (100025252). <OutboundLink href="https://drive.google.com/file/d/1NRtSfTDcuEjrdyoJi0M9iNHhei1bMGG6/view?usp=sharing">View larger format</OutboundLink>.
        </p>

        <h3>Resident permit holders</h3>

        <p>If you have a valid Caledonian Village parking permit  (in accordance with the <OutboundLink href="https://drive.google.com/file/d/1r6EcmWSWR_kHqrZv2BWUaqYb9GaL15QD/view?usp=share_link">permit policy</OutboundLink>) then you can park within any of the orange residential parking areas in the map above, which are the block paved areas to the rear and sides of the properties.</p>

        <h3>Uncontrolled parking</h3>

        <p>The main access roads through Caledonian Village have been <OutboundLink href="https://www.edinburgh.gov.uk/roads-pavements/statutory-list-public-roads/1">adopted by the Council</OutboundLink>. You may park within parking bays on these streets without a resident permit (e.g. when visiting, or while waiting for a Caledonian Village parking permit). Any UKPC signs still in existence are no longer valid at these locations.</p>
      </div>
    </Layout> 
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "images/parking-areas.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ParkingAdvicePage
